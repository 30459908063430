const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    API_URL: 'http://localhost:8080',
    LOGIN_URL: `https://test-lmit-finditems-nprod-ping-dhounkje6q-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
  },
  test: {
    API_URL: 'https://test-finditems-api-dhounkje6q-ew.a.run.app',
    LOGIN_URL: `https://test-lmit-finditems-nprod-ping-dhounkje6q-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
  },
  prod: {
    API_URL: 'https://prod-finditems-api-w2e3et7fzq-ew.a.run.app',
    LOGIN_URL: `https://prod-lmit-finditems-prd-ping-w2e3et7fzq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
  },
}

export default envConfig[env]
