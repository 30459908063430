import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Link } from 'react-router-dom'

import User from 'Components/User'
import Menu from 'Components/Menu'
import { StyledHeader, HeaderBar, AppLogo } from './styled'

const Header = props => {
  const { user, changeStore } = props
  return (
    <>
      <HeaderBar />
      <StyledHeader>
        <Link to={'/'}>
          <AppLogo src={'/assets/img/logo.png'} />
        </Link>
        <Menu />
        {!isEmpty(user) ? (
          <User user={user} onClick={user.isAdmin ? changeStore : null} />
        ) : null}
      </StyledHeader>
    </>
  )
}

Header.propTypes = {
  changeStore: PropTypes.func,
  user: PropTypes.object,
}
export default Header
