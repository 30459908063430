import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { StyledFooter } from './styled'

const Footer = props => {
  const { storeId } = props
  return (
    <StyledFooter>
      <small>{`v${process.env.REACT_APP_VERSION}`}</small>
      {storeId ? (
        <small>
          <FormattedMessage id={'footer.label.store'} values={{ storeId }} />
        </small>
      ) : null}
    </StyledFooter>
  )
}
Footer.propTypes = {
  storeId: PropTypes.number,
}
export default Footer
