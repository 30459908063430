import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is, isNil } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Table, Empty, Input, Icon, Avatar } from 'antd'

import Loader from 'Components/Loader'
import Button from 'Components/Button'

import { colors } from 'Common/constants'
import { toTitleCase } from 'Common/utils'

class PositionsTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    openEditModal: PropTypes.func,
    rowSelection: PropTypes.object,
  }
  state = {
    filteredData: {},
  }

  setFilters = filters => this.setState({ filteredData: filters })
  clearFilters = () => this.setState({ filteredData: {} })

  sorter(a, b, dataIndex) {
    if (is(Number, a[dataIndex]) || is(Boolean, a[dataIndex]))
      return a[dataIndex] - b[dataIndex]
    if (is(String, a[dataIndex]))
      return a[dataIndex].localeCompare(b[dataIndex])
    return 1
  }
  searchFilterProps(dataIndex) {
    const { filteredData } = this.state
    const { intl } = this.props
    return {
      filteredValue: filteredData[dataIndex] || [],
      filterDropdown: props => {
        const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props
        return (
          <>
            <Input
              ref={node => {
                this.searchInput = node
              }}
              placeholder={intl.formatMessage({
                id: 'positionsTable.placeholder.search',
              })}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={confirm}
              style={{ margin: 12, width: 150 }}
            />
            <div className={'ant-table-filter-dropdown-btns'}>
              <span
                className={'ant-table-filter-dropdown-link confirm'}
                onClick={confirm}>
                <FormattedMessage id={'positionsTable.button.confirm'} />
              </span>
              <span
                className={'ant-table-filter-dropdown-link clear'}
                onClick={clearFilters}>
                <FormattedMessage id={'positionsTable.button.clear'} />
              </span>
            </div>
          </>
        )
      },
      filterIcon: filtered => (
        <Icon
          type={'search'}
          style={{ color: filtered ? colors.main : undefined }}
        />
      ),
      onFilter: (value, row) =>
        row[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible =>
        visible && setTimeout(() => this.searchInput.select()),
    }
  }
  render() {
    const { intl, isLoading, data, openEditModal, rowSelection } = this.props
    const columns = [
      {
        key: 'description',
        title: intl.formatMessage({ id: 'positionsTable.label.description' }),
        dataIndex: 'description',
        sorter: (a, b) => this.sorter(a, b, 'description'),
        ...this.searchFilterProps('description'),
      },
      {
        key: 'department',
        title: intl.formatMessage({ id: 'positionsTable.label.department' }),
        dataIndex: 'department',
        render: department =>
          department?.name ? toTitleCase(department.name) : '',
        sorter: (a, b) => this.sorter(a, b, 'department'),
      },
      {
        key: 'floor',
        title: intl.formatMessage({ id: 'positionsTable.label.floor' }),
        dataIndex: 'floor',
        sorter: (a, b) => this.sorter(a, b, 'floor'),
      },
      {
        key: 'rows',
        title: intl.formatMessage({ id: 'positionsTable.label.rows' }),
        dataIndex: 'rows',
        render: rows => rows.map(row => row.name).join(', '),
        sorter: (a, b) => this.sorter(a, b, 'rows'),
      },
      {
        key: 'inShowroom',
        title: intl.formatMessage({ id: 'positionsTable.label.inShowroom' }),
        dataIndex: 'inShowroom',
        width: 130,
        render: inShowroom =>
          isNil(inShowroom)
            ? intl.formatMessage({ id: 'positionsTable.label.inShowroom.null' })
            : inShowroom
            ? intl.formatMessage({ id: 'positionsTable.label.inShowroom.true' })
            : intl.formatMessage({
                id: 'positionsTable.label.inShowroom.false',
              }),
        sorter: (a, b) => this.sorter(a, b, 'inShowroom'),
      },
      {
        key: 'newCategory',
        title: intl.formatMessage({ id: 'positionsTable.label.newCategory' }),
        dataIndex: 'newCategory',
        sorter: (a, b) => this.sorter(a, b, 'newCategory'),
        render: newCategory =>
          newCategory ? (
            <Avatar
              size={'small'}
              icon={'check'}
              style={{ backgroundColor: colors.success }}
            />
          ) : null,
      },
      {
        render: row => (
          <Button icon={'edit'} ghost onClick={() => openEditModal([row])} />
        ),
      },
    ]
    return (
      <Table
        onChange={(pagination, filters) => this.setFilters(filters)}
        size={'middle'}
        dataSource={data}
        columns={columns}
        rowSelection={rowSelection}
        pagination={{ pageSize: 25 }}
        rowKey={row => `${row.id}-${row.parentId}`}
        loading={{ spinning: isLoading, indicator: <Loader /> }}
        locale={{
          emptyText: (
            <Empty
              description={intl.formatMessage({
                id: 'positionsTable.placeholder.empty',
              })}
            />
          ),
        }}
      />
    )
  }
}

export default injectIntl(PositionsTable)
