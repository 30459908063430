import API from './axiosConfig'
import { BU } from './constants'

export default {
  user: {
    authenticate: token =>
      API.get('/user', {
        headers: { Authorization: `Bearer ${token}` },
      }),
  },
  store: {
    list: () => API.get('/stores', { params: { bu: BU } }),
  },
  departments: {
    get: storeId => API.get('/departments', { params: { storeId, bu: BU } }),
  },
  rows: {
    get: storeId => API.get('/rows', { params: { storeId, bu: BU } }),
    update: (body, storeId) =>
      API.post('/row', body, { params: { storeId, bu: BU } }),
    delete: (id, storeId) =>
      API.delete(`/row/${id}`, { params: { storeId, bu: BU } }),
  },
  positions: {
    get: storeId => API.get('/positions', { params: { storeId, bu: BU } }),
    update: (body, storeId) =>
      API.post('/positions', body, { params: { storeId, bu: BU } }),
  },
}
