// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const UPDATE_STORE_ID = 'UPDATE_STORE_ID'

export const GET_STORES = 'GET_STORES'

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const GET_ROWS = 'GET_ROWS'
export const UPDATE_ROW = 'UPDATE_ROW'
export const DELETE_ROW = 'DELETE_ROW'
export const GET_POSITIONS = 'GET_POSITIONS'
export const UPDATE_POSITIONS = 'UPDATE_POSITIONS'
