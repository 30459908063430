import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'antd'
import { StyledFeedbackButton } from './styled'

const FeedbackButton = props => {
  const { onClick } = props
  return (
    <StyledFeedbackButton
      type={'primary'}
      size={'small'}
      shape={'round'}
      onClick={onClick}>
      <Icon type={'like'} theme={'filled'} />
      <FormattedMessage id={'feedback.button.text'} />
    </StyledFeedbackButton>
  )
}
export default FeedbackButton
