import {
  GET_STORES,
  GET_DEPARTMENTS,
  GET_ROWS,
  GET_POSITIONS,
} from '../action-types'

export const initialState = {
  stores: [],
  departments: [],
  rows: [],
  positions: [],
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STORES:
      return { ...state, stores: payload }
    case GET_DEPARTMENTS:
      return { ...state, departments: payload }
    case GET_ROWS:
      return { ...state, rows: payload }
    case GET_POSITIONS:
      return { ...state, positions: payload }
    default:
      return state
  }
}

export default reducer
