import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal } from 'antd'
import { connect } from 'react-redux'

import {
  getRows,
  updateRow,
  deleteRow,
  getDepartments,
} from 'Redux/actions/app-actions'

import RowsTable from 'Components/RowsTable'
import RowModal from 'Components/RowModal'
import { PageTitle } from 'Common/styled'
import Button from 'Components/Button'

class RowManager extends Component {
  static propTypes = {
    departments: PropTypes.array,
    deleteRow: PropTypes.func,
    getDepartments: PropTypes.func,
    getRows: PropTypes.func,
    rows: PropTypes.array,
    updateRow: PropTypes.func,
  }
  state = {
    isLoading: false,
    isRowModalVisible: false,
    hasError: false,
    selectedTableRow: {},
  }

  componentDidMount() {
    this.getRows()
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props
    if (prevProps.user.storeId !== user.storeId) this.getRows()
  }

  getRows = async () => {
    const { getRows } = this.props
    this.setState({ isLoading: true })
    await getRows()
    this.setState({ isLoading: false })
  }

  updateRow = async rows => {
    const { updateRow } = this.props
    this.setState({ isLoading: true, hasError: false })

    const res = await updateRow(rows)
    if (res?.status === 202) {
      this.setState({ isLoading: false, hasError: true })
    } else {
      this.getRows()
      this.closeRowModal()
    }
  }

  deleteRow = async id => {
    const { intl, deleteRow } = this.props
    Modal.confirm({
      title: intl.formatMessage({ id: 'rowManager.confirmModal.title' }),
      cancelText: intl.formatMessage({
        id: 'rowManager.confirmModal.cancelText',
      }),
      onOk: async () => {
        this.setState({ isLoading: true })
        await deleteRow(id)

        this.getRows()
      },
    })
  }

  getDepartments = async () => {
    const { getDepartments } = this.props
    this.setState({ isLoading: true })
    await getDepartments()
    this.setState({ isLoading: false })
  }

  openRowModal = row => {
    this.getDepartments()
    this.setState(prevState => ({
      isRowModalVisible: true,
      selectedTableRow: row || prevState.selectedTableRow,
    }))
  }
  closeRowModal = () =>
    this.setState({
      isRowModalVisible: false,
      selectedTableRow: {},
      hasError: false,
    })

  submitRowForm = values => {
    const { selectedTableRow } = this.state
    const { departments } = this.props
    const department = values.newDepartment
      ? { name: values.newDepartment }
      : departments.find(dep => dep.id === +values.department)

    const parsedRow = {
      ...selectedTableRow,
      ...omit(['newDepartment'], values),
      department,
    }
    this.updateRow(parsedRow)
  }

  render() {
    const {
      isLoading,
      isRowModalVisible,
      hasError,
      selectedTableRow,
    } = this.state
    const { rows, departments } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'rowManager.title'} />
        </PageTitle>
        <Button
          icon={'plus'}
          style={{ width: 'max-content' }}
          onClick={() => this.openRowModal()}>
          <FormattedMessage id={'rowManager.button.add'} />
        </Button>
        <RowsTable
          isLoading={isLoading}
          data={rows}
          openEditModal={this.openRowModal}
          deleteRow={this.deleteRow}
        />
        <RowModal
          isLoading={isLoading}
          isVisible={isRowModalVisible}
          hasError={hasError}
          data={selectedTableRow}
          departments={departments}
          closeModal={this.closeRowModal}
          submitForm={this.submitRowForm}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  rows: state.app.rows,
  departments: state.app.departments,
})
const mapDispatchToProps = {
  getRows,
  updateRow,
  deleteRow,
  getDepartments,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RowManager))
