import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import {
  getRows,
  getPositions,
  updatePositions,
} from 'Redux/actions/app-actions'

import Button from 'Components/Button'
import PositionsTable from 'Components/PositionsTable'
import PositionModal from 'Components/PositionModal'
import { PageTitle } from 'Common/styled'
import { Alert } from 'antd'

class Home extends Component {
  static propTypes = {
    getPositions: PropTypes.func,
    getRows: PropTypes.func,
    positions: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    updatePositions: PropTypes.func,
    user: PropTypes.object,
  }

  state = {
    isLoading: false,
    isPositionModalVisible: false,
    selectedTableRows: [],
    selectedTableRowKeys: [],
  }

  componentDidMount() {
    this.getPositions()
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props
    if (prevProps.user.storeId !== user.storeId) this.getPositions()
  }

  getPositions = () => {
    const { getPositions } = this.props
    this.setState({ isLoading: true })
    getPositions().then(() => this.setState({ isLoading: false }))
  }

  updatePositions = async rows => {
    const { updatePositions } = this.props
    this.setState({ isLoading: true })

    await updatePositions(rows)
    this.getPositions()
    this.closePositionModal()
  }

  getRows = () => {
    const { getRows } = this.props
    this.setState({ isLoading: true })
    getRows().then(() => this.setState({ isLoading: false }))
  }

  openPositionModal = rows => {
    this.getRows()
    this.setState(prevState => ({
      isPositionModalVisible: true,
      selectedTableRows: rows || prevState.selectedTableRows,
    }))
  }
  closePositionModal = () =>
    this.setState({
      isPositionModalVisible: false,
      selectedTableRows: [],
      selectedTableRowKeys: [],
    })

  submitPositionForm = values => {
    const { selectedTableRows } = this.state
    const { rows } = this.props

    const parsedRows = selectedTableRows.map(selectedTableRow => ({
      ...selectedTableRow,
      rows: values.rows.map(row => rows.find(r => r.id === row)),
    }))
    this.updatePositions(parsedRows)
  }

  render() {
    const {
      isLoading,
      isPositionModalVisible,
      selectedTableRows,
      selectedTableRowKeys,
    } = this.state
    const { intl, rows, positions } = this.props
    const newCategories = positions.filter(item => item.newCategory)
    const tableRowSelection = {
      onChange: (selectedTableRowKeys, selectedTableRows) =>
        this.setState({ selectedTableRowKeys, selectedTableRows }),
      selectedTableRowKeys,
    }
    const selectedTableRowsCount = selectedTableRows.length
    const selectedTableRowKeysCount = selectedTableRowKeys.length
    const selectedTableRow =
      selectedTableRowsCount === 1
        ? selectedTableRows[0]
        : { length: selectedTableRowsCount }
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'home.title'} />
        </PageTitle>
        {!isEmpty(newCategories) ? (
          <Alert
            banner
            type={'warning'}
            message={intl.formatMessage(
              { id: 'home.alert.newCategories' },
              { count: newCategories.length }
            )}
          />
        ) : null}
        {selectedTableRowKeysCount > 0 ? (
          <Button
            style={{
              width: 'max-content',
            }}
            onClick={() => this.openPositionModal()}>
            {selectedTableRowKeysCount > 1 ? (
              <FormattedMessage
                id={'home.button.edit.multiple'}
                values={{ count: selectedTableRowKeysCount }}
              />
            ) : (
              <FormattedMessage id={'home.button.edit.single'} />
            )}
          </Button>
        ) : null}
        <PositionsTable
          isLoading={isLoading}
          data={positions}
          openEditModal={this.openPositionModal}
          rowSelection={tableRowSelection}
        />
        <PositionModal
          isLoading={isLoading}
          isVisible={isPositionModalVisible}
          data={selectedTableRow}
          rows={rows}
          closeModal={this.closePositionModal}
          submitForm={this.submitPositionForm}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  rows: state.app.rows,
  positions: state.app.positions,
})
const mapDispatchToProps = {
  getRows,
  getPositions,
  updatePositions,
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
