import { notification } from 'antd'

import config from 'Common/config'
const { LOGIN_URL } = config

export const toTitleCase = string =>
  string
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()))

export const getShortName = (firstName, lastName) =>
  `${firstName} ${lastName.substring(0, 1)}.`

export const getQueryParam = param => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}

export const handleServerError = error => {
  const status = error.response && error.response.status
  const data = error.response && error.response.data
  // Redirect to login
  if (status === 401) {
    window.location.href = LOGIN_URL
  }
  return notification.error({
    message: status ? `Errore ${status}` : 'Si è verificato un errore',
    description: data && data.message ? data.message : 'Riprova',
  })
}
