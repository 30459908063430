import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Form, Modal, Select } from 'antd'

import Loader from 'Components/Loader'
import { FormItem } from 'Common/styled'

class PositionModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isVisible: PropTypes.bool,
    submitForm: PropTypes.func,
  }

  closeModal = () => {
    const { form, closeModal } = this.props
    form.resetFields()
    closeModal()
  }
  submitForm = () => {
    const { form, submitForm } = this.props
    form.validateFields((errors, values) => {
      if (!errors) {
        submitForm(values)
      }
    })
  }

  render() {
    const {
      intl,
      form,
      isLoading,
      isVisible,
      data,
      rows: storeRows,
    } = this.props
    const { description, rows, length } = data
    const title = length
      ? intl.formatMessage(
          { id: 'positionModal.title.multiple' },
          { count: length }
        )
      : description
    const storeFloors = storeRows.reduce((acc, row) => {
      if (!acc.some(el => el === row.floor)) {
        acc.push(row.floor)
      }
      return acc.sort((a, b) => a - b)
    }, [])

    return (
      <Modal
        title={title}
        visible={isVisible}
        okText={intl.formatMessage({ id: 'positionModal.okText' })}
        onOk={this.submitForm}
        cancelText={intl.formatMessage({ id: 'positionModal.cancelText' })}
        onCancel={this.closeModal}>
        {isLoading ? (
          <Loader height={'100px'} />
        ) : (
          <Form>
            <FormItem
              label={intl.formatMessage({ id: 'positionModal.label.rows' })}>
              {form.getFieldDecorator('rows', {
                initialValue: rows?.map(row => row.id),
              })(
                <Select
                  mode={'multiple'}
                  placeholder={intl.formatMessage({
                    id: 'positionModal.placeholder.rows',
                  })}
                  optionFilterProp={'children'}>
                  {storeFloors.map(floor => (
                    <Select.OptGroup
                      key={floor}
                      label={intl.formatMessage(
                        { id: 'positionModal.label.floor' },
                        { floor }
                      )}>
                      {storeRows
                        ?.filter(row => row.floor === floor)
                        .map(row => (
                          <Select.Option value={row.id} key={row.id}>
                            {row.name}
                          </Select.Option>
                        ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              )}
            </FormItem>
            <small>
              <FormattedMessage id={'positionModal.text'} />
            </small>
          </Form>
        )}
      </Modal>
    )
  }
}

export default Form.create()(injectIntl(PositionModal))
