import {
  GET_STORES,
  GET_DEPARTMENTS,
  GET_ROWS,
  GET_POSITIONS,
  UPDATE_POSITIONS,
  UPDATE_ROW,
  DELETE_ROW,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getStores = () => async dispatch => {
  try {
    const res = await api.store.list()
    const stores = res.data
    return dispatch({ type: GET_STORES, payload: stores })
  } catch (error) {
    console.log(error)
    return handleServerError(error)
  }
}

export const getDepartments = () => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.departments.get(storeId)
    return dispatch({ type: GET_DEPARTMENTS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
export const getRows = () => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.rows.get(storeId)
    return dispatch({ type: GET_ROWS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateRow = data => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.rows.update(data, storeId)
    if (res.status === 202) {
      const error = { response: { status: res.status, data: res.data } }
      handleServerError(error)
      return error.response
    }
    return dispatch({ type: UPDATE_ROW })
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteRow = id => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    await api.rows.delete(id, storeId)
    return dispatch({ type: DELETE_ROW })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getPositions = () => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.positions.get(storeId)
    const positions = res.data.sort((a, b) =>
      a.description.localeCompare(b.description)
    )
    dispatch({ type: GET_POSITIONS, payload: positions })
  } catch (error) {
    return handleServerError(error)
  }
}

export const updatePositions = data => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    await api.positions.update(data, storeId)
    dispatch({ type: UPDATE_POSITIONS })
  } catch (error) {
    return handleServerError(error)
  }
}
