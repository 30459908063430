import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { validateToken } from 'Redux/actions/user-actions'

import Loader from 'Components/Loader'

import config from 'Common/config'

const { LOGIN_URL } = config

class Auth extends Component {
  static propTypes = {
    user: PropTypes.object,
    validateToken: PropTypes.func,
  }
  componentDidMount() {
    const { user, location, history, validateToken } = this.props
    const queryParams = new URLSearchParams(location.search)
    const tokenParam = queryParams.get('token')
    if (!tokenParam) {
      window.location.href = LOGIN_URL
    } else {
      const token = tokenParam || user.token
      validateToken(token).then(() => {
        queryParams.delete('token')
        history.replace(`${location.pathname}?${queryParams.toString()}`)
      })
    }
  }

  render() {
    const { user, children } = this.props
    return isEmpty(user) ? <Loader /> : children
  }
}
const mapStateToProps = state => ({
  user: state.user,
})
const mapDispatchToProps = {
  validateToken,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth))
