import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Form, Input, InputNumber, Modal, Select } from 'antd'

import Loader from 'Components/Loader'
import { FormItem } from 'Common/styled'

class RowModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    data: PropTypes.object,
    departments: PropTypes.array,
    isLoading: PropTypes.bool,
    isVisible: PropTypes.bool,
    submitForm: PropTypes.func,
  }
  state = { hasNewDepartment: false }

  componentDidUpdate(prevProps, prevState) {
    const { hasError } = this.props
    if (prevProps.hasError !== hasError && hasError) {
      this.setState({ hasNewDepartment: false })
    }
  }

  closeModal = () => {
    const { form, closeModal } = this.props
    this.setState({ hasNewDepartment: false })
    form.resetFields()
    closeModal()
  }
  submitForm = () => {
    const { form, submitForm } = this.props
    form.validateFields((errors, values) => {
      if (!errors) {
        submitForm(values)
      }
    })
  }

  selectDepartment = value => {
    const { intl } = this.props
    const isNewDepartmentValue = intl.formatMessage({
      id: 'rowModal.value.other',
    })
    if (value === isNewDepartmentValue) {
      this.setState({ hasNewDepartment: true })
    }
  }
  resetNewDepartment = () => this.setState({ hasNewDepartment: false })

  render() {
    const { hasNewDepartment } = this.state
    const { intl, form, isLoading, isVisible, data, departments } = this.props
    const { name, building, floor, departmentId } = data
    const title = isEmpty(data)
      ? intl.formatMessage({ id: 'rowModal.title.add' })
      : intl.formatMessage({ id: 'rowModal.title.edit' }, { name })

    return (
      <Modal
        title={title}
        visible={isVisible}
        okText={intl.formatMessage({ id: 'rowModal.okText' })}
        onOk={this.submitForm}
        cancelText={intl.formatMessage({ id: 'rowModal.cancelText' })}
        onCancel={this.closeModal}>
        {isLoading ? (
          <Loader height={'100px'} />
        ) : (
          <Form>
            <FormItem
              label={intl.formatMessage({ id: 'rowModal.label.rowName' })}>
              {form.getFieldDecorator('name', {
                initialValue: name,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'rowModal.validation.rowName',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({
                    id: 'rowModal.placeholder.rowName',
                  })}
                />
              )}
            </FormItem>
            <FormItem
              label={intl.formatMessage({ id: 'rowModal.label.department' })}>
              <>
                {form.getFieldDecorator('department', {
                  initialValue: departmentId,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'rowModal.validation.department',
                      }),
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder={intl.formatMessage({
                      id: 'rowModal.placeholder.department',
                    })}
                    optionFilterProp={'children'}
                    onChange={this.selectDepartment}>
                    {departments?.map(department => (
                      <Select.Option key={department.id} value={department.id}>
                        {department.name}
                      </Select.Option>
                    ))}
                    <Select.Option
                      value={intl.formatMessage({
                        id: 'rowModal.value.other',
                      })}>
                      <FormattedMessage id={'rowModal.label.other'} />
                    </Select.Option>
                  </Select>
                )}
                {hasNewDepartment
                  ? form.getFieldDecorator('newDepartment')(
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'rowModal.placeholder.newDepartment',
                        })}
                      />
                    )
                  : null}
              </>
            </FormItem>
            <FormItem
              label={intl.formatMessage({ id: 'rowModal.label.building' })}>
              {form.getFieldDecorator('building', {
                initialValue: building || 0,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'rowModal.validation.building',
                    }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({
                    id: 'rowModal.placeholder.building',
                  })}>
                  <Select.Option value={0}>
                    <FormattedMessage id={'rowModal.label.store'} />
                  </Select.Option>
                  <Select.Option value={1}>
                    <FormattedMessage id={'rowModal.label.showroom'} />
                  </Select.Option>
                </Select>
              )}
            </FormItem>
            <FormItem
              label={intl.formatMessage({ id: 'rowModal.label.floor' })}>
              {form.getFieldDecorator('floor', {
                initialValue: floor || 0,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'rowModal.validation.floor',
                    }),
                  },
                ],
              })(
                <InputNumber
                  placeholder={intl.formatMessage({
                    id: 'rowModal.placeholder.floor',
                  })}
                  min={0}
                  max={3}
                />
              )}
            </FormItem>
          </Form>
        )}
      </Modal>
    )
  }
}

export default Form.create()(injectIntl(RowModal))
