export const BU = 5
export const locationValues = [
  { key: 'destra', value: 'Destra' },
  { key: 'sinistra', value: 'Sinistra' },
]
export const placementValues = [
  { key: 'inizio', value: 'Inizio' },
  { key: 'centro', value: 'Centro' },
  { key: 'fine', value: 'Fine' },
]
