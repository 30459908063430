import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Table, Empty, Input, Icon } from 'antd'

import Loader from 'Components/Loader'
import Button from 'Components/Button'

import { colors } from 'Common/constants'

class RowsTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    deleteRow: PropTypes.func,
    isLoading: PropTypes.bool,
    openEditModal: PropTypes.func,
  }
  state = {
    filteredData: {},
  }

  setFilters = filters => this.setState({ filteredData: filters })
  clearFilters = () => this.setState({ filteredData: {} })

  sorter(a, b, dataIndex) {
    if (is(Number, a[dataIndex]) || is(Boolean, a[dataIndex]))
      return a[dataIndex] - b[dataIndex]
    if (is(String, a[dataIndex]))
      return a[dataIndex].localeCompare(b[dataIndex])
    return 1
  }
  searchFilterProps(dataIndex) {
    const { filteredData } = this.state
    const { intl } = this.props
    return {
      filteredValue: filteredData[dataIndex] || [],
      filterDropdown: props => {
        const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props
        return (
          <>
            <Input
              ref={node => {
                this.searchInput = node
              }}
              placeholder={intl.formatMessage({
                id: 'rowsTable.placeholder.search',
              })}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={confirm}
              style={{ margin: 12, width: 150 }}
            />
            <div className={'ant-table-filter-dropdown-btns'}>
              <span
                className={'ant-table-filter-dropdown-link confirm'}
                onClick={confirm}>
                <FormattedMessage id={'rowsTable.button.confirm'} />
              </span>
              <span
                className={'ant-table-filter-dropdown-link clear'}
                onClick={clearFilters}>
                <FormattedMessage id={'rowsTable.button.clear'} />
              </span>
            </div>
          </>
        )
      },
      filterIcon: filtered => (
        <Icon
          type={'search'}
          style={{ color: filtered ? colors.main : undefined }}
        />
      ),
      onFilter: (value, row) =>
        row[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible =>
        visible && setTimeout(() => this.searchInput.select()),
    }
  }
  render() {
    const { intl, isLoading, data, openEditModal, deleteRow } = this.props
    const columns = [
      {
        key: 'name',
        title: intl.formatMessage({ id: 'rowsTable.label.rowName' }),
        dataIndex: 'name',
        sorter: (a, b) => this.sorter(a, b, 'Id'),
        ...this.searchFilterProps('name'),
      },
      {
        key: 'departmentName',
        title: intl.formatMessage({ id: 'rowsTable.label.department' }),
        dataIndex: 'departmentName',
        sorter: (a, b) => this.sorter(a, b, 'departmentName'),
      },
      {
        key: 'building',
        title: intl.formatMessage({ id: 'rowsTable.label.building' }),
        dataIndex: 'building',
        render: building =>
          building === 0
            ? intl.formatMessage({ id: 'rowsTable.label.building.store' })
            : intl.formatMessage({ id: 'rowsTable.label.building.showroom' }),
        sorter: (a, b) => this.sorter(a, b, 'building'),
      },
      {
        key: 'floor',
        title: intl.formatMessage({ id: 'rowsTable.label.floor' }),
        dataIndex: 'floor',
        sorter: (a, b) => this.sorter(a, b, 'floor'),
      },
      {
        render: row => (
          <>
            <Button icon={'edit'} ghost onClick={() => openEditModal(row)} />{' '}
            <Button
              icon={'delete'}
              type={'danger'}
              ghost
              onClick={() => deleteRow(row.id)}
            />
          </>
        ),
      },
    ]
    return (
      <Table
        onChange={(pagination, filters) => this.setFilters(filters)}
        size={'middle'}
        dataSource={data}
        columns={columns}
        rowKey={row => row.id}
        loading={{ spinning: isLoading, indicator: <Loader /> }}
        locale={{
          emptyText: (
            <Empty
              description={intl.formatMessage({
                id: 'rowsTable.placeholder.empty',
              })}
            />
          ),
        }}
      />
    )
  }
}

export default injectIntl(RowsTable)
