import styled from 'styled-components'
import { Layout, Button, Form } from 'antd'
import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 120px);
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));
`
export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
`
export const PageTitle = styled.h1`
  color: ${colors.main};
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`
export const FormItem = styled(Form.Item)`
  margin: 0;
  & label {
    color: ${colors.grey4};
    font-weight: 500;
    text-transform: uppercase;
  }
`
